<template>
  <v-card elevation="1">
    <v-toolbar height="45" flat color="accent" light>
      <v-toolbar-title class="white--text">{{ $route.meta.title }}</v-toolbar-title>
    </v-toolbar>
    <v-divider class="mb-2"></v-divider>
    <v-tabs color="accent">
      <v-tab>
        <v-icon left>{{ icons.mdiPlus }}</v-icon>
        Customer Info
      </v-tab>
      <v-tab>
        <v-icon left>{{ icons.mdiInformation }}</v-icon>
        Additional Info
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="customer.first_name"
                    color="secondary"
                    label="First Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="6" cols="12">
                  <v-text-field
                    v-model="customer.last_name"
                    color="secondary"
                    label="Last Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <div v-for="(phone, index) in customer.phones" :key="index">
                    <v-select
                      v-model="phone.type"
                      color="secondary"
                      dense
                      outlined
                      label="Type"
                      :items="items"
                    ></v-select>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <div v-for="(phone, index) in customer.phones" :key="index">
                    <v-text-field
                      v-model="phone.tel_number"
                      color="secondary"
                      dense
                      label="Phone Number"
                      outlined
                    ></v-text-field>
                  </div>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="customer.email"
                    dense
                    label="Email (Optional)"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    background-color="transparent"
                    v-model="customer.notes"
                    color="secondary"
                    dense
                    label="Notes (Optional)"
                    outlined
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <v-card-text>
            <v-form class="multi-col-validation">
              <v-row>
                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="customer.company_name"
                    color="secondary"
                    label="Company Name"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col md="12" cols="12">
                  <v-text-field
                    v-model="customer.address1"
                    color="secondary"
                    label="Address 1"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                  <v-text-field
                    v-model="customer.address2"
                    color="secondary"
                    label="Address 2"
                    dense
                    outlined
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="customer.city"
                    dense
                    label="Town/City"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="customer.province"
                    dense
                    label="Province"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="customer.postal_code"
                    dense
                    label="Postal Code"
                    outlined
                    color="secondary"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                  <v-switch class="mt-n2" flat label="Should this be taxed?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer receive a discount?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor rate override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a labor matrix override?"></v-switch>
                  <v-switch class="mt-n3" flat label="Does this customer have a pricing matrix override?"></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="accent" rounded @click.prevent="saveCustomer">
              <v-icon right dark>{{ icons.mdiContentSave }}</v-icon>
              <span>Save</span>
            </v-btn>
            <v-btn color="secondary" rounded @click.prevent="$router.push({ name: 'customer' })">
              <v-icon right dark>{{ icons.mdiClose }}</v-icon>
              <span>Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiAccountTie, mdiPlus, mdiInformation, mdiContentSave, mdiClose } from '@mdi/js'
export default {
  components: {},
  created: function () {
    this.getCustomerId()
  },
  data() {
    return {
      customer: {},
      items: ['Mobile', 'Work', 'Home', 'Other'],
      steps: 1,
      totalsteps: 2,
      icons: {
        mdiAccountTie,
        mdiPlus,
        mdiInformation,
        mdiContentSave,
        mdiClose,
      },
    }
  },
  methods: {
    getCustomerId: function () {
      this.$store
        .dispatch(`customer/editCustomer`, {
          customer_id: this.$route.params.id,
        })
        .then(response => {
          ;(this.customer = this.fetchCustomers), response
        })
    },

    saveCustomer: function () {
      this.$store
        .dispatch(`customer/updateNewCustomer`, {
          customer_id: this.$route.params.id,
          data: this.customer,
        })
        .then(response => {
          const Toast = this.$swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          })

          Toast.fire({
            icon: 'success',
            title: 'Record updated successfully',
          })
          this.$router.push({ name: 'customer' })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },

  computed: {
    ...mapGetters({
      fetchCustomers: 'customer/fetchCustomers',
    }),
  },
}
</script>

<style lang="scss" scoped>
</style>